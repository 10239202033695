import autocomplete from "../components/autocomplete";

export function loadAutoComplete(input) {
  autocomplete(input);
}

export let latitude = null;
export let longitude = null;


let showSearch = false;
export function toggleSearch() {
  document.querySelector('#search-container').style.display = (!showSearch) ? 'block' : 'none';
  document.querySelector('#close-search-icon').style.display = (!showSearch) ? 'block' : 'none';
  document.querySelector('#search-icon').style.display = (!showSearch) ? 'none' : 'block'; 
  document.querySelector('#menu-icon').style.display = (!showSearch) ? 'none' : 'block';
  showSearch = !showSearch;

  if (showSearch) {
    document.querySelector('#search').value = '';
    document.querySelector('#search').focus();

    document.body.querySelector('main').style.display = 'none';
    
  } else {
    document.body.querySelector('main').style.display = 'flex';
  }
}


